.vertical-social .middayInner{
    overflow: hidden !important;
    height: max-content;
    min-height: 219px;
}
.flip-clock-wrapper ul {
    width: 60px;
    height: 90px;
}

/*Background colours*/
.flip-clock-wrapper .inn {
    background-color: #fff;
    font-size: 70px;
}
